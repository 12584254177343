<template>
    <div class="LoginError">
        <div class="LoginErrorBody">
            <div class="LoginErrorBody__title">
                <div class="LoginErrorBody__title--logo">
                    <i class="iconfont icon-logo-18bai"></i>
                </div>
                <div class="LoginErrorBody__title--text">
                    您好，欢迎使用中呼400查号
                </div>
            </div>
            <div class="LoginErrorBody__alert">
                系统检查到您还未完成微信号绑定，请按照一下步骤完成绑定后，再次尝试使用。
            </div>
            <my-timeline class="LoginErrorBody__timeline">
                <my-timeline-item :index="1" explain="使用您的账号登录合作伙伴端" title="登录合作伙伴管理">
                    <van-image :src="step1" height="200" width="250" />
                </my-timeline-item>
                <my-timeline-item :index="2" explain="在系统右上角点击账号，并选择“账户设置”" title="进入账号设置">
                    <van-image :src="step2" height="200" width="250" />
                </my-timeline-item>
                <my-timeline-item :index="3" explain="点击“绑定微信”用微信扫描二维码完成绑定" title="绑定微信号">
                    <van-image :src="step3" height="83" width="250" />
                </my-timeline-item>
            </my-timeline>
        </div>
        <div class="LoginErrorFooter">
            <van-button block type="info" @click="onLogin">刷新重试</van-button>
        </div>
    </div>
</template>

<script>
    import {MyTimeline, MyTimelineItem} from "@/components/MyPlugins";
    import step1 from "@/assets/loginError/step1.png";
    import step2 from "@/assets/loginError/step2.png";
    import step3 from "@/assets/loginError/step3.png";
    import {mapMutations} from "vuex";

    export default {
        name: "LoginError",
        components: {
            MyTimeline,
            MyTimelineItem
        },
        data() {
            return {
                step1,
                step2,
                step3,
            }
        },
        methods: {
            ...mapMutations('user', ['setLoginState']),
            onLogin() {
                this.setLoginState();
                this.$router.push({path: '/', replace: true});
            }
        }
    }
</script>

<style lang="scss" scoped>
    .LoginError {
        height: 100vh;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        &Body {
            flex: 1;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            &__title {
                background: #4D88FD;
                display: flex;
                align-items: center;
                padding: 20px;
                &--logo {
                    height: 60px;
                    width: 60px;
                    display: table;
                    .icon-logo-18bai {
                        font-size: 31px;
                        vertical-align: middle;
                        display: table-cell;
                        color: #FFFFFF;
                    }
                }
                &--text {
                    color: #FFFFFF;
                    margin-left: 20px;
                    font-weight: 600;
                }
            }
            &__alert {
                padding: 16px 10px;
                line-height: 20px;
                color: #999999;
            }
            &__timeline {
                margin: 0 20px;
            }
        }
        &Footer {
            padding: 16px;
        }
    }
</style>
